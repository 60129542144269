// Add your JS customizations here

jQuery(function () {
    
    'use strict';
    
    // Give Active Class
    
    // jQuery('.project-title').first().addClass('active');
    
    // Show First Info
    
    // jQuery('.project-content').first().show().animate({width: '100%'});
    
    // Show Info On Click
    
    jQuery('.project-title').click(function () {
        
        jQuery(this).addClass("active").siblings('.project-title').removeClass('active');

        jQuery(this).next().show().animate({width: '100%'}).siblings('.project-content').animate({width: 0}, function () {
            
            jQuery(this).hide();
            
        });
        
    });
});